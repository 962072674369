/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
import * as React from 'react';
import PIX from 'react-qrcode-pix';
import { useContext, useEffect, useRef, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, DialogContentText, IconButton, InputAdornment, Snackbar, TextField } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const now = new Date().getTime().toString();

export default function App() {
  const [chave, setChave] = useState('');
  const [cidade, setCidade] = useState('');
  const [cep, setCep] = useState('');
  const [nome, setNome] = useState('');
  const [fullPIX, setFullPIX] = useState('');
  const [open, setOpen] = React.useState(false);
  const [valorTotal, setValortotal] = useState('');

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      textRef.current.value = fullPIX;
    }
  }, [fullPIX]);

  return (
    <div className="flex flex-col items-center w-full">
      <div className="mt-5 flex flex-col">
        <TextField
          style={ { marginBottom: 10 } }
          label="Chave Pix"
          value={ chave }
          onChange={ (e) => setChave(e.target.value) }
        />
        <TextField
          style={ { marginBottom: 10 } }
          label="Cidade"
          value={ cidade }
          onChange={ (e) => setCidade(e.target.value) }
        />
        <TextField
          style={ { marginBottom: 10 } }
          label="CEP"
          value={ cep }
          onChange={ (e) => setCep(e.target.value) }
        />
        <TextField
          style={ { marginBottom: 10 } }
          label="Nome"
          value={ nome }
          onChange={ (e) => setNome(e.target.value) }
        />
        <TextField
          type="number"
          style={ { marginBottom: 10 } }
          label="Valor"
          value={ valorTotal }
          onChange={ (e) => setValortotal(e.target.value) }
        />
      </div>
      <PIX
        pixkey={ chave }
        merchant={ nome }
        city={ cidade.replace(/ /g, '_') }
        cep={ cep }
        code={ `RQP${now}` }
        amount={ Number(valorTotal) }
        onLoad={ setFullPIX }
        resize={ 200 }
        variant="fluid"
        padding={ 10 }
        color="#357"
        bgColor="white"
        bgRounded
      />
      <div
        className="mt-3 lg:w-[700px] w-full p-5 bg-slate-100"
      >
        <div className="max-w-96" />
        <CopyToClipboard
          id="whats"
          text={ fullPIX }
        >
          <TextField
            id="whats"
            style={ { width: '100%' } }
            className="w-full"
            disabled
            inputRef={ textRef }
            label="Pix Copia e Cola"
            fullWidth
            onClick={ handleClick }
            InputProps={ {
              endAdornment: (
                <InputAdornment id="whats" position="end">
                  <IconButton
                    id="whats"
                    aria-label="copiar texto"
                    edge="end"
                  >
                    <ContentCopyIcon id="whats" />
                  </IconButton>
                </InputAdornment>
              ),
            } }
          />
        </CopyToClipboard>
        <div className={ `mt-3 ${open ? '' : 'hidden'}` }>
          <Alert
            severity="success"
          >
            Pix Copia e Cola copiado com sucesso!
          </Alert>
        </div>
      </div>
      <p className="w-[90%]">
        <span className="font-bold">Formatos válidos de chave PIX:</span>
        <br />
        <span className="font-bold">EMAIL:</span>
        {' '}
        fulano_da_silva.recebedor@example.com
        <br />
        <span className="font-bold">CPF:</span>
        {' '}
        12345678900
        <br />
        <span className="font-bold">CNPJ:</span>
        {' '}
        12345678901234
        <br />
        <span className="font-bold">TELEFONE:</span>
        {' '}
        +5511912345678
        <br />
        <span className="font-bold">ALEATORIA:</span>
        {' '}
        123e4567-e12b-12d1-a456-426655440000
      </p>
    </div>
  );
}
